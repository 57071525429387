import React, { useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import DropDownErrorMessage from "../components/common/dropDownErrorMessage";
import { countries, countryCode } from "../components/constants/constants";
import CustomSelect from "../components/customSelect/customSelect";
import TextField from "../components/textField/textField";
import { AppContext } from "../context/app";
import { CREATE_ACCOUNT } from "../apiUrls";
import useAxios from "axios-hooks";
import useUploadS3Hook from "../s3upload/useUploadS3Hook";

const Wrapper = styled.div`
  padding: 60px 108px;
  margin-left: auto;
  width: 75%;
  .w30 {
    width: 30%;
  }
  .mr34 {
    margin-right: 34px;
  }
`;

const FieldLabel = styled.div`
  p {
    font-size: 16px;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 66px;
  color: #35a2d1;
`;

const NextButton = styled.button`
  color: #fff;
  background: #35a2d1;
  border-radius: 5px;
  padding: 10px 32px;
  border: none;
`;
const CustomLink = styled(Link)`
  color: #35a2d1;
  font-size: 16px;
  font-weight: bold;
`;

const titleItems = [
  { value: "mr", label: "MR." },
  { value: "mrs", label: "MRS." },
];

const Fundraiser = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    getValues,
  } = useForm();
  const navigate = useNavigate();
  const { handleUploadFiles } = useUploadS3Hook();
  const accountId = localStorage.getItem("accountId");
  const { setLoader } = useContext(AppContext);
  const [
    { data: updateAccount, loading: updateAccountLoading },
    updateAccountCall,
  ] = useAxios(
    {
      url: `${CREATE_ACCOUNT}/${accountId}`,
      method: "patch",
    },
    { manual: true }
  );
  useEffect(() => {
    setLoader(updateAccountLoading);
  }, [updateAccountLoading]);

  useEffect(() => {
    if (updateAccount && updateAccount.success) {
      navigate("/thank-you");
    }
  }, [updateAccount]);

  const onSubmit = async (data) => {
    let uploadStatus = await handleUploadFiles([
      { ...data?.passportScan },
      { ...data.dlScan },
      { ...data.internationalAddress },
    ]);

    // {
    //   INDIVIDUAL_INVESTOR = '',
    //   INSTITUTIONAL_INVESTOR = 'INSTITUTIONAL_INVESTOR',
    //   FUNDRAISER = 'FUNDRAISER',
    // }
    if (uploadStatus.success) {
      let patchPayload = {
        accountRole: "FUNDRAISER",
        accountRoleDetails: {
          personalDetails: {
            title: data?.title?.value,
            dateOfBirth: data?.dob,
            countryOfResidence: data.country.label,
            citizenship: data.citizenship.label,
            countryCode: data?.countryCode?.value,
            contactNumber: data.mobileNumber,
          },
          details: {
            country: data.detailsCountry.label,
            street: data.streetAddress,
            city: data.city,
            building: data.building,
            potcode: data.postalCode,
            state: data.stateOrCountry,
          },
          companyDetails: {
            companyName: data?.companyName,
            companyNumber: data?.comapnyNumber,
            countryCode: data?.companyCountryCode?.value,
            contactNumber: data?.compDetailsNumber,
            companyEmail: data?.compDetailsEmail,
            companyWebsite: data?.companyWebsite,
          },
          documentsUrl: {
            passport: uploadStatus.data[0].s3Key,
            drivingLicense: uploadStatus.data[1].s3Key,
            internationalAddress: uploadStatus.data[2].s3Key,
          },
        },
      };

      await updateAccountCall({ data: patchPayload });
    }
  };

  //   console.log("get", getValues());

  return (
    <Wrapper>
      <p className="fs36 fwB mb40">FundRaiser</p>
      <p className="fs20 fwB mb24">PERSONAL DETAILS</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FieldLabel className=" w100 mb32">
          <p className="fs16 mb16 fwB">TITLE </p>
          <Controller
            name="title"
            control={control}
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <CustomSelect
                  placeholder="Title"
                  {...field}
                  options={titleItems}
                />
              );
            }}
          />
          {errors.title && (
            <DropDownErrorMessage>Title is required</DropDownErrorMessage>
          )}{" "}
        </FieldLabel>
        <FieldLabel className="fwB w100 mb32">
          <p className="fs16 mb16">DATE OF BIRTH</p>
          <TextField
            id="dob"
            label="DD/MM/YY"
            type="date"
            name="dob"
            errors={errors}
            register={register}
            validationSchema={{
              required: "Last Name is required",
              minLength: {
                value: 3,
                message: "Please enter a minimum of 3 characters",
              },
            }}
            required
          />
        </FieldLabel>
        <FieldLabel className="w100 mb32">
          <p className="fs16 mb16 fwB">COUNTRY OF RESIDENCE</p>
          <Controller
            name="country"
            control={control}
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <CustomSelect
                  placeholder="Country"
                  {...field}
                  options={countries}
                />
              );
            }}
          />
          {errors.country && (
            <DropDownErrorMessage>Country is required</DropDownErrorMessage>
          )}
        </FieldLabel>
        <FieldLabel className=" w100 mb32">
          <p className="fs16 mb16 fwB">CITIZENSHIP</p>
          <Controller
            name="citizenship"
            control={control}
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <CustomSelect
                  placeholder="Citizenship"
                  {...field}
                  options={countries}
                />
              );
            }}
          />
          {errors.citizenship && (
            <DropDownErrorMessage>Citizenship is required</DropDownErrorMessage>
          )}
        </FieldLabel>
        <div className="dF mb64">
          <FieldLabel className="w30 mr34 ">
            <p className="fs16 mb16 fwB">PHONE COUNTRY CODE</p>
            <Controller
              name="countryCode"
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                return (
                  <CustomSelect
                    placeholder="Country Code"
                    {...field}
                    options={countryCode.map((info) => ({
                      value: info.dialingCode,
                      label: `${info.dialingCode} ${info.countryName}`,
                    }))}
                  />
                );
              }}
            />
            {errors.countryCode && (
              <DropDownErrorMessage>
                Country Code is required
              </DropDownErrorMessage>
            )}
          </FieldLabel>
          <FieldLabel className="fwB w30">
            <p className="fs16 mb16">MOBILE NUMBER</p>
            <TextField
              id="mobileNumber"
              label="XXXXX"
              type="number"
              min="0"
              name="mobileNumber"
              errors={errors}
              register={register}
              validationSchema={{
                required: "Mobile Number is required",
                validate: {
                  pattern: (value) =>
                    value.trim() && !value.match(/^[+0-9()\s-]{6,15}$/)
                      ? "Please enter a valid phone number"
                      : true,
                },
              }}
              required
            />
          </FieldLabel>
        </div>
        <p className="fs20 fwB mb18">DETAILS</p>
        <FieldLabel className="w100 mb32">
          <p className="fs16 mb16 fwB">COUNTRY</p>
          <Controller
            name="detailsCountry"
            control={control}
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <CustomSelect
                  placeholder="Country"
                  {...field}
                  options={countries}
                />
              );
            }}
          />
          {errors.detailsCountry && (
            <DropDownErrorMessage>Country is required</DropDownErrorMessage>
          )}
        </FieldLabel>
        <FieldLabel className="w100 mb32">
          <p className="fs16 mb16 fwB">STREET ADDRESS</p>
          <TextField
            id="streetAddress"
            label="Street Address"
            type="text"
            name="streetAdress"
            errors={errors}
            register={register}
            validationSchema={{
              required: "Street Address is required",
              minLength: {
                value: 3,
                message: "Please enter a minimum of 3 characters",
              },
            }}
            required
          />
        </FieldLabel>
        <FieldLabel className="w100 mb32">
          <p className="fs16 mb16 fwB">CITY</p>
          <TextField
            id="city"
            label="City"
            type="text"
            name="city"
            errors={errors}
            register={register}
            validationSchema={{
              required: "City is required",
            }}
            required
          />
        </FieldLabel>
        <FieldLabel className="w100 mb32">
          <p className="fs16 mb16 fwB">BUILDING / OPTIONAL</p>
          <TextField
            id="building"
            label="Bulding"
            type="text"
            name="building"
            errors={errors}
            register={register}
          />
        </FieldLabel>
        <FieldLabel className="w100 mb32">
          <p className="fs16 mb16 fwB">POSTAL CODE</p>
          <TextField
            id="postalCode"
            label="Postal Code"
            type="text"
            name="postalCode"
            errors={errors}
            register={register}
            validationSchema={{
              required: "Postal Code is required",
              minLength: {
                value: 3,
                message: "Please enter a minimum of 3 characters",
              },
            }}
            required
          />
        </FieldLabel>
        <FieldLabel className="w100 mb64">
          <p className="fs16 mb16 fwB">STATE / COUNTRY (OPTIONAL)</p>
          <TextField
            id="stateOrCountry"
            label="State / Country"
            type="text"
            name="stateOrCountry"
            errors={errors}
            register={register}
          />
        </FieldLabel>
        <p className="fs20 fwB mb16">COMPANY DETAILS</p>
        <FieldLabel className="fwB w100 mb32">
          <p className="mb16">COMPANY NAME</p>
          <TextField
            id="companyName"
            label="Company Name"
            type="text"
            name="companyName"
            errors={errors}
            register={register}
            validationSchema={{
              required: "Company Name is required",
              minLength: {
                value: 3,
                message: "Please enter a minimum of 3 characters",
              },
            }}
            required
          />
        </FieldLabel>
        <FieldLabel className="fwB w100 mb32">
          <p className="mb16">COMPANY NUMBER</p>
          <TextField
            id="comapnyNumber"
            label="Company Number"
            type="number"
            name="comapnyNumber"
            errors={errors}
            register={register}
            validationSchema={{
              required: "Company Number is required",
              minLength: {
                value: 3,
                message: "Please enter a minimum of 3 characters",
              },
            }}
            required
          />
        </FieldLabel>
        <div className="dF mb32">
          <FieldLabel className="w30 mr34 ">
            <p className="fs16 mb16 fwB">PHONE COUNTRY CODE</p>
            <Controller
              name="companyCountryCode"
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                return (
                  <CustomSelect
                    placeholder="Country Code"
                    {...field}
                    options={countryCode.map((info) => ({
                      value: info.dialingCode,
                      label: `${info.dialingCode} ${info.countryName}`,
                    }))}
                  />
                );
              }}
            />
            {errors.companyCountryCode && (
              <DropDownErrorMessage>
                Country code is required
              </DropDownErrorMessage>
            )}
          </FieldLabel>
          <FieldLabel className="fwB w30">
            <p className="fs16 mb16">MOBILE NUMBER</p>
            <TextField
              id="compDetailsNumber"
              label="XXXXX"
              type="number"
              name="compDetailsNumber"
              errors={errors}
              register={register}
              validationSchema={{
                required: "Company Mobile Number is required",
                minLength: {
                  value: 3,
                  message: "Please enter a minimum of 3 characters",
                },
              }}
              required
            />
          </FieldLabel>
        </div>
        <FieldLabel className="fwB w100 mb32">
          <p className="mb16">EMAIL</p>
          <TextField
            id="compDetailsEmail"
            label="Email"
            type="email"
            name="compDetailsEmail"
            errors={errors}
            register={register}
            validationSchema={{
              required: "Email is required",
              pattern: {
                value: new RegExp(
                  /^([A-Za-z0-9_\-.])+@\w{2,}\.\w{2,}(\.\w{2,})?$/
                ),
                message: "Please enter a valid emial",
              },
            }}
            required
          />
        </FieldLabel>
        <FieldLabel className="fwB w100 mb40">
          <p className="mb16">COMPANY WEBSITE</p>
          <TextField
            id="companyWebsite"
            label="Company Website"
            type="text"
            name="companyWebsite"
            errors={errors}
            register={register}
            validationSchema={{
              required: "Company Website is required",
              minLength: {
                value: 3,
                message: "Please enter a minimum of 3 characters",
              },
            }}
            required
          />
        </FieldLabel>
        <p className="fs20 fwB mb18">DETAILS ( COMPANY REGESTRATION ADDRESS)</p>
        <FieldLabel className="w100 mb32">
          <p className="fs16 mb16 fwB">COUNTRY</p>
          <Controller
            name="compRegistrationcountry"
            control={control}
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <CustomSelect
                  placeholder="Country"
                  {...field}
                  options={countries}
                />
              );
            }}
          />
          {errors.compRegistrationcountry && (
            <DropDownErrorMessage>Country is required</DropDownErrorMessage>
          )}{" "}
        </FieldLabel>
        <FieldLabel className="w100 mb32">
          <p className="fs16 mb16 fwB">STREET ADDRESS</p>
          <TextField
            id="streetAddress"
            label="Street Address"
            type="text"
            name="streetAdress"
            errors={errors}
            register={register}
            validationSchema={{
              required: "Street Address is required",
              minLength: {
                value: 3,
                message: "Please enter a minimum of 3 characters",
              },
            }}
            required
          />
        </FieldLabel>
        <FieldLabel className="w100 mb32">
          <p className="fs16 mb16 fwB">CITY</p>
          <TextField
            id="city"
            label="City"
            type="text"
            name="city"
            errors={errors}
            register={register}
            validationSchema={{
              required: "City is required",
            }}
            required
          />
        </FieldLabel>
        <FieldLabel className="w100 mb32">
          <p className="fs16 mb16 fwB">BUILDING / OPTIONAL</p>
          <TextField
            id="compRegistrationBuilding"
            label="Bulding"
            type="text"
            name="compRegistrationBuilding"
            errors={errors}
            register={register}
          />
        </FieldLabel>
        <FieldLabel className="w100 mb32">
          <p className="fs16 mb16 fwB">POSTAL CODE</p>
          <TextField
            id="postalCode"
            label="Postal Code"
            type="text"
            name="postalCode"
            errors={errors}
            register={register}
            validationSchema={{
              required: "Postal Code is required",
              minLength: {
                value: 3,
                message: "Please enter a minimum of 3 characters",
              },
            }}
            required
          />
        </FieldLabel>
        <FieldLabel className="w100 mb32">
          <p className="fs16 mb16 fwB">STATE / COUNTRY (OPTIONAL)</p>
          <TextField
            id="stateOrCountry"
            label="State / Country"
            type="text"
            name="stateOrCountry"
            errors={errors}
            register={register}
          />
        </FieldLabel>
        <FieldLabel className="fwB w100 mb64">
          <p className="mb16">COMPANY NUMBER</p>
          <TextField
            id="comapnyNumber"
            label="Company Number"
            type="number"
            name="comapnyNumber"
            errors={errors}
            register={register}
            validationSchema={{
              required: "Company Number is required",
              minLength: {
                value: 3,
                message: "Please enter a minimum of 3 characters",
              },
            }}
            required
          />
        </FieldLabel>
        <p className="fs20 fwB mb16">UPLOAD DOCUMENTS</p>
        <p className="fs12 mb16 mb30 cAluminum">
          THE MAXIMUM SIZE PER FILE IS 7MB . THE FOLLOWING FORMATS ARE ACCEPTED
          : PDF, JPEG, PNG, JPG, GIF.
        </p>
        <p className="fs16 mb30 fwB">STAKEHOLDER IDENTITY DOCUMENTS</p>
        <div className="dFA">
          <div style={{ width: "50%" }} className="">
            <FieldLabel className="w100 mb32">
              <p className="fs16 mb16 fwB">SCAN OF PASSPORT</p>
              <TextField
                id="passportScan"
                label="Passport"
                type="file"
                name="passportScan"
                accept="image/png, image/jpg, image/jpeg"
                errors={errors}
                register={register}
                validationSchema={{
                  required: "Postal Code is required",
                  minLength: {
                    value: 3,
                    message: "Please enter a minimum of 3 characters",
                  },
                }}
                required
              />
            </FieldLabel>
            <FieldLabel className="w100 mb32">
              <p className="fs16 mb16 fwB">DRIVING LICENCE</p>
              <TextField
                id="dlScan"
                label="Driving Licence"
                type="file"
                name="dlScan"
                accept="image/png, image/jpg, image/jpeg"
                errors={errors}
                register={register}
                validationSchema={{
                  required: "Driving Licence is required",
                }}
                required
              />
            </FieldLabel>
            <FieldLabel className="w100 mb32">
              <p className="fs16 mb16 fwB">INTERNATIONAL ADDRESS</p>
              <TextField
                id="internationalAddress"
                label="Passport"
                type="file"
                name="internationalAddress"
                accept="image/png, image/jpg, image/jpeg"
                errors={errors}
                register={register}
                validationSchema={{
                  required: "International Address is required",
                }}
                required
              />
            </FieldLabel>
          </div>
          <div style={{ width: "50%" }} className="dFA jcC">
            <button
              style={{
                padding: "10px 32px",
                color: "white",
                borderRadius: "5px",
                backgroundColor: "#3498db",
                border: "none",
              }}
              onClick={() =>
                window.open("/kyc-details", "_blank", "noreferrer")
              }
            >
              Initiate KYC
            </button>
          </div>
        </div>

        <ButtonWrapper className="dFA jcSB">
          <CustomLink to="/categorisation">PREVIOUS STEP</CustomLink>
          <NextButton type="submit">NEXT STEP</NextButton>
        </ButtonWrapper>
      </form>
    </Wrapper>
  );
};

export default Fundraiser;
