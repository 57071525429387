import React from "react";
import styled from "styled-components";
import FundtLogo from "../assets/fundt-logo.png";

const Wrapper = styled.div`
  height: 100vh;
`;

const ThankYou = () => {
  return (
    <Wrapper style={{ flexDirection: "column" }} className="dFA jcC">
      <img src={FundtLogo} alt="Fundt Logo" style={{ width: "200px" }} />
      <p style={{ marginTop: "20px" }} className="fs36 fwB">
        THANK YOU FOR YOUR REGISTRATION
      </p>
      <p style={{ marginTop: "20px" }} className="">
        YOUR PROFILE IS UNDER VERIFICATION WE WILL NOTIFY YOU AS SOON AS YOUR
        PROFILE IS VERIFIED.
      </p>
    </Wrapper>
  );
};

export default ThankYou;
