import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import TextField from "../components/textField/textField";
import { useNavigate } from "react-router-dom";
import useAxios from "axios-hooks";
import { CREATE_ACCOUNT } from "../apiUrls";
import { AppContext } from "../context/app";

const DetailsWrapper = styled.div`
  padding: 87px 157px;
  background: #d9d9d9;
  width: 75%;
  margin-left: auto;
  .cBP {
    color: #828d93;
  }
  .title {
    margin-bottom: 82px;
  }
`;

const FieldLabel = styled.div`
  p {
    font-size: 16px;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 66px;
  color: #35a2d1;
`;

const NextButton = styled.button`
  a {
    color: #fff;
  }
  color: #fff;
  background: #35a2d1;
  border-radius: 5px;
  padding: 10px 32px;
  border: none;
`;

const UserRegistration = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();
  const navigate = useNavigate();
  const { setLoader, setUser } = useContext(AppContext);
  const [
    { data: createAccount, loading: createAccountoading },
    createAccountCall,
  ] = useAxios(
    {
      url: CREATE_ACCOUNT,
      method: "post",
    },
    { manual: true }
  );

  useEffect(() => {
    setLoader(createAccountoading);
  }, [createAccountoading]);

  useEffect(() => {
    if (createAccount && createAccount?.success) {
      setUser(createAccount?.accessToken);
      navigate("/categorisation");
    }
  }, [createAccount]);

  const onSubmit = (data) => {
    delete data.confirmPassword;
    console.log(data);
    createAccountCall({
      data: { ...data },
    });
  };

  return (
    <DetailsWrapper>
      <div className="dFA jcSB title">
        <p className="fwB fs36">REGISTRATION</p>
        <NextButton className="fwB">
          <a href="http://user.fundt.om/login">LOGIN</a>
        </NextButton>
      </div>
      <p className="fs24 mb32 cBP">PLEASE FILL THE FOLLWING INFORMATION </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FieldLabel className="fwB w100 mb32">
          <p className="mb16">FIRST NAME</p>
          <TextField
            id="firstName"
            label="First Name"
            type="text"
            name="firstName"
            errors={errors}
            register={register}
            validationSchema={{
              required: "First Name is required",
              minLength: {
                value: 3,
                message: "Please enter a minimum of 3 characters",
              },
            }}
            required
          />
        </FieldLabel>
        <FieldLabel className="fwB w100 mb32">
          <p className="mb16">LAST NAME</p>
          <TextField
            id="lastName"
            label="Last Name"
            type="text"
            name="lastName"
            errors={errors}
            register={register}
            validationSchema={{
              required: "Last Name is required",
              minLength: {
                value: 3,
                message: "Please enter a minimum of 3 characters",
              },
            }}
            required
          />
        </FieldLabel>
        <FieldLabel className="fwB w100 mb64">
          <p className="mb16">PASSWORD</p>
          <TextField
            id="password"
            label="Password"
            type="password"
            name="password"
            helpText="(The password must contain an uppercase letter, a lowercase letter, a number, a symbol and be at least 8 characters long)"
            errors={errors}
            register={register}
            validationSchema={{
              required: "Password is required",
              pattern: {
                value: new RegExp(
                  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\d\s])[A-Za-z\d\W]{8,}$/
                ),
                message: "Please enter a valid password",
              },
            }}
            required
          />
        </FieldLabel>
        <FieldLabel className="fwB w100 mb32">
          <p className="mb16">CONFIRM PASSWORD</p>
          <TextField
            id="confirmPassword"
            label="Confirm Password"
            type="password"
            name="confirmPassword"
            errors={errors}
            register={register}
            validationSchema={{
              required: "This is required field",
              validate: (val) => {
                if (getValues("password") !== val) {
                  return "Your passwords do no match";
                }
              },
            }}
            required
          />
        </FieldLabel>
        <FieldLabel className="fwB w100 mb32">
          <p className="mb16">EMAIL</p>
          <TextField
            id="email"
            label="Email"
            type="email"
            name="email"
            errors={errors}
            register={register}
            validationSchema={{
              required: "Email is required",
              pattern: {
                value: new RegExp(
                  /^([A-Za-z0-9_\-.])+@\w{2,}\.\w{2,}(\.\w{2,})?$/
                ),
                message: "Please enter a valid emial",
              },
            }}
            required
          />
        </FieldLabel>
        <ButtonWrapper className="dFA jcSB">
          <p className="fs16 fwB"></p>
          <NextButton type="submit">NEXT STEP</NextButton>
        </ButtonWrapper>
      </form>
    </DetailsWrapper>
  );
};

export default UserRegistration;
