export const countries = [
  {
    value: "afganistan",
    label: "Afghanistan",
  },
  {
    value: "alaska (usa)",
    label: "ALASKA (USA)",
  },
  {
    value: "albania",
    label: "Albania",
  },
  {
    value: "algeria",
    label: "Algeria",
  },
  {
    value: "american samoa",
    label: "AMERICAN SAMOA",
  },
  {
    value: "andorra",
    label: "Andorra",
  },
  {
    value: "angola",
    label: "Angola",
  },
  {
    value: "anguilia",
    label: "ANGUILLA",
  },
  {
    value: "antigua & barbuda",
    label: "ANTIGUA & BARBUDA",
  },
  {
    value: "argentina",
    label: "Argentina",
  },
  {
    value: "armenia",
    label: "Armenia",
  },
  {
    value: "aruba",
    label: "Aruba",
  },
  {
    value: "ascension",
    label: "Ascension",
  },
  {
    value: "australia",
    label: "Australia",
  },
  {
    value: "austria",
    label: "Austria",
  },
  {
    value: "azerbaijan",
    label: "Azerbaijan",
  },
  {
    value: "bahamas",
    label: "BAHAMAS",
  },
  {
    value: "bahrain",
    label: "Bahrain",
  },
  {
    value: "bangladesh",
    label: "Bangladesh",
  },
  {
    value: "barbados",
    label: "BARBADOS",
  },
  {
    value: "belarus",
    label: "Belarus",
  },
  {
    value: "belgium",
    label: "Belgium",
  },
  {
    value: "belize",
    label: "Belize",
  },
  {
    value: "Bbenin",
    label: "Benin",
  },
  {
    value: "bermuda",
    label: "BERMUDA",
  },
  {
    value: "bhutan",
    label: "Bhutan",
  },
  {
    value: "bolivia",
    label: "Bolivia",
  },
  {
    value: "bosnia and herzegovina",
    label: "Bosnia and Herzegovina",
  },
  {
    value: "botswana",
    label: "Botswana",
  },
  {
    value: "brazil",
    label: "Brazil",
  },
  {
    value: "british virginislands",
    label: "BRITISH VIRGINISLANDS",
  },
  {
    value: "brunei",
    label: "Brunei",
  },
  {
    value: "bulgaria",
    label: "Bulgaria",
  },
  {
    value: "burkina faso",
    label: "Burkina Faso",
  },
  {
    value: "burundi",
    label: "Burundi",
  },
  {
    value: "cambodia",
    label: "Cambodia",
  },
  {
    value: "cameroon",
    label: "Cameroon",
  },
  {
    value: "canada",
    label: "Canada",
  },
  {
    value: "cape verde",
    label: "Cape Verde",
  },
  {
    value: "cayman islands",
    label: "CAYMAN ISLANDS",
  },
  {
    value: "central african republic",
    label: "Central African Republic",
  },
  {
    value: "chad",
    label: "Chad",
  },
  {
    value: "chile",
    label: "Chile",
  },
  {
    value: "china",
    label: "China",
  },
  {
    value: "colombia",
    label: "Colombia",
  },
  {
    value: "comoros",
    label: "Comoros",
  },
  {
    value: "congo",
    label: "Congo",
  },
  //   need to change the value after this
  {
    value: "48",
    label: "Cook Islands",
  },
  {
    value: "49",
    label: "Costa Rica",
  },
  {
    value: "50",
    label: "Croatia",
  },
  {
    value: "51",
    label: "Cuba",
  },
  {
    value: "52",
    label: "Cyprus",
  },
  {
    value: "53",
    label: "Czechia",
  },
  {
    value: "54",
    label: "Denmark",
  },
  {
    value: "55",
    label: "Diego Garcia",
  },
  {
    value: "56",
    label: "Djibouti",
  },
  {
    value: "57",
    label: "DOMINICA",
  },
  {
    value: "58",
    label: "DOMINICAN REPUBLIC",
  },
  {
    value: "59",
    label: "Ecuador",
  },
  {
    value: "60",
    label: "Egypt",
  },
  {
    value: "61",
    label: "El Salvador",
  },
  {
    value: "62",
    label: "Equatorial Guinea",
  },
  {
    value: "63",
    label: "Eritrea",
  },
  {
    value: "64",
    label: "Estonia",
  },
  {
    value: "65",
    label: "Ethiopia",
  },
  {
    value: "66",
    label: "Faroe Islands",
  },
  {
    value: "67",
    label: "Fiji",
  },
  {
    value: "68",
    label: "Finland",
  },
  {
    value: "69",
    label: "France",
  },
  {
    value: "70",
    label: "French Guiana",
  },
  {
    value: "71",
    label: "French Polynesia",
  },
  {
    value: "72",
    label: "Gabon",
  },
  {
    value: "73",
    label: "Gambia",
  },
  {
    value: "74",
    label: "Georgia",
  },
  {
    value: "75",
    label: "Germany",
  },
  {
    value: "76",
    label: "Ghana",
  },
  {
    value: "77",
    label: "Gibraltar",
  },
  {
    value: "78",
    label: "Greece",
  },
  {
    value: "79",
    label: "Greenland",
  },
  {
    value: "80",
    label: "GRENADA",
  },
  {
    value: "81",
    label: "GUAM",
  },
  {
    value: "82",
    label: "Guatemala",
  },
  {
    value: "83",
    label: "Guinea",
  },
  {
    value: "84",
    label: "Guyana",
  },
  {
    value: "85",
    label: "Haiti",
  },
  {
    value: "86",
    label: "HAWAII (USA)",
  },
  {
    value: "87",
    label: "Honduras",
  },
  {
    value: "88",
    label: "Hong Kong",
  },
  {
    value: "89",
    label: "Hungary",
  },
  {
    value: "90",
    label: "Iceland",
  },
  {
    value: "91",
    label: "India",
  },
  {
    value: "92",
    label: "Indonesia",
  },
  {
    value: "93",
    label: "Iran",
  },
  {
    value: "94",
    label: "Iraq",
  },
  {
    value: "95",
    label: "Ireland",
  },
  {
    value: "96",
    label: "Israel",
  },
  {
    value: "97",
    label: "Italy",
  },
  {
    value: "98",
    label: "JAMAICA",
  },
  {
    value: "99",
    label: "Japan",
  },
  {
    value: "100",
    label: "Jordan",
  },
  {
    value: "101",
    label: "Kazakhstan",
  },
  {
    value: "102",
    label: "Kenya",
  },
  {
    value: "103",
    label: "Kiribati",
  },
  {
    value: "104",
    label: "North Korea",
  },
  {
    value: "105",
    label: "South Korea",
  },
  {
    value: "106",
    label: "Kuwait",
  },
  {
    value: "107",
    label: "Latvia",
  },
  {
    value: "108",
    label: "Lebanon",
  },
  {
    value: "109",
    label: "Lesotho",
  },
  {
    value: "110",
    label: "Liberia",
  },
  {
    value: "111",
    label: "Libya",
  },
  {
    value: "112",
    label: "Liechtenstein",
  },
  {
    value: "113",
    label: "Lithuania",
  },
  {
    value: "114",
    label: "Luxembourg",
  },
  {
    value: "115",
    label: "Madagascar",
  },
  {
    value: "116",
    label: "Malawi",
  },
  {
    value: "117",
    label: "Malaysia",
  },
  {
    value: "118",
    label: "Maldives",
  },
  {
    value: "119",
    label: "Mali",
  },
  {
    value: "120",
    label: "Malta",
  },
  {
    value: "121",
    label: "Marshall Islands",
  },
  {
    value: "122",
    label: "Mauritania",
  },
  {
    value: "123",
    label: "Mauritius",
  },
  {
    value: "124",
    label: "Mayotte",
  },
  {
    value: "125",
    label: "Mexico",
  },
  {
    value: "126",
    label: "Moldova",
  },
  {
    value: "127",
    label: "Monaco",
  },
  {
    value: "128",
    label: "Mongolia",
  },
  {
    value: "129",
    label: "MONTSERRAT",
  },
  {
    value: "130",
    label: "Morocco",
  },
  {
    value: "131",
    label: "Mozambique",
  },
  {
    value: "132",
    label: "Myanmar",
  },
  {
    value: "133",
    label: "Namibia",
  },
  {
    value: "134",
    label: "Nauru",
  },
  {
    value: "135",
    label: "Nepal",
  },
  {
    value: "136",
    label: "Netherlands",
  },
  {
    value: "137",
    label: "Netherlands Antilles",
  },
  {
    value: "138",
    label: "New Caledonia",
  },
  {
    value: "139",
    label: "New Zealand",
  },
  {
    value: "140",
    label: "Nicaragua",
  },
  {
    value: "141",
    label: "Niger",
  },
  {
    value: "142",
    label: "Nigeria",
  },
  {
    value: "143",
    label: "Norway",
  },
  {
    value: "144",
    label: "Oman",
  },
  {
    value: "145",
    label: "Pakistan",
  },
  {
    value: "146",
    label: "Palau",
  },
  {
    value: "147",
    label: "Palestine",
  },
  {
    value: "148",
    label: "Panama",
  },
  {
    value: "149",
    label: "Papua New Guinea",
  },
  {
    value: "150",
    label: "Paraguay",
  },
  {
    value: "151",
    label: "Peru",
  },
  {
    value: "152",
    label: "Philippines",
  },
  {
    value: "153",
    label: "Poland",
  },
  {
    value: "154",
    label: "Portugal",
  },
  {
    value: "155",
    label: "Qatar",
  },
  {
    value: "156",
    label: "Romania",
  },
  {
    value: "157",
    label: "Russia",
  },
  {
    value: "158",
    label: "Rwanda",
  },
  {
    value: "159",
    label: "San Marino",
  },
  {
    value: "160",
    label: "Saudi Arabia",
  },
  {
    value: "161",
    label: "Senegal",
  },
  {
    value: "162",
    label: "Seychelles",
  },
  {
    value: "163",
    label: "Sierra Leone",
  },
  {
    value: "164",
    label: "Singapore",
  },
  {
    value: "165",
    label: "Slovakia",
  },
  {
    value: "166",
    label: "Slovenia",
  },
  {
    value: "167",
    label: "Solomon Islands",
  },
  {
    value: "168",
    label: "Somalia",
  },
  {
    value: "169",
    label: "Sout",
  },
];

export const countryCode = [
  {
    countryId: "1",
    countryName: "Afghanistan",
    countryCode: "AF",
    dialingCode: "+93",
  },
  {
    countryId: "2",
    countryName: "ALASKA (USA)",
    countryCode: "AK",
    dialingCode: "+1-907",
  },
  {
    countryId: "3",
    countryName: "Albania",
    countryCode: "AL",
    dialingCode: "+355",
  },
  {
    countryId: "4",
    countryName: "Algeria",
    countryCode: "DZ",
    dialingCode: "+213",
  },
  {
    countryId: "5",
    countryName: "AMERICAN SAMOA",
    countryCode: "AS",
    dialingCode: "+1-684",
  },
  {
    countryId: "6",
    countryName: "Andorra",
    countryCode: "AD",
    dialingCode: "+376",
  },
  {
    countryId: "7",
    countryName: "Angola",
    countryCode: "AO",
    dialingCode: "+244",
  },
  {
    countryId: "8",
    countryName: "ANGUILLA",
    countryCode: "AI",
    dialingCode: "+1-264",
  },
  {
    countryId: "9",
    countryName: "ANTIGUA & BARBUDA",
    countryCode: "AG",
    dialingCode: "+1-268",
  },
  {
    countryId: "10",
    countryName: "Argentina",
    countryCode: "AR",
    dialingCode: "+54",
  },
  {
    countryId: "11",
    countryName: "Armenia",
    countryCode: "AM",
    dialingCode: "+374",
  },
  {
    countryId: "12",
    countryName: "Aruba",
    countryCode: "AW",
    dialingCode: "+297",
  },
  {
    countryId: "13",
    countryName: "Ascension",
    countryCode: "AC",
    dialingCode: "+247",
  },
  {
    countryId: "14",
    countryName: "Australia",
    countryCode: "AU",
    dialingCode: "+61",
  },
  {
    countryId: "15",
    countryName: "Austria",
    countryCode: "AT",
    dialingCode: "+43",
  },
  {
    countryId: "16",
    countryName: "Azerbaijan",
    countryCode: "AZ",
    dialingCode: "+994",
  },
  {
    countryId: "17",
    countryName: "BAHAMAS",
    countryCode: "BS",
    dialingCode: "+1-242",
  },
  {
    countryId: "18",
    countryName: "Bahrain",
    countryCode: "BH",
    dialingCode: "+973",
  },
  {
    countryId: "19",
    countryName: "Bangladesh",
    countryCode: "BD",
    dialingCode: "+880",
  },
  {
    countryId: "20",
    countryName: "BARBADOS",
    countryCode: "BB",
    dialingCode: "+1-246",
  },
  {
    countryId: "21",
    countryName: "Belarus",
    countryCode: "BY",
    dialingCode: "+375",
  },
  {
    countryId: "22",
    countryName: "Belgium",
    countryCode: "BE",
    dialingCode: "+32",
  },
  {
    countryId: "23",
    countryName: "Belize",
    countryCode: "BZ",
    dialingCode: "+501",
  },
  {
    countryId: "24",
    countryName: "Benin",
    countryCode: "BJ",
    dialingCode: "+229",
  },
  {
    countryId: "25",
    countryName: "BERMUDA",
    countryCode: "BM",
    dialingCode: "+1-441",
  },
  {
    countryId: "26",
    countryName: "Bhutan",
    countryCode: "BT",
    dialingCode: "+975",
  },
  {
    countryId: "27",
    countryName: "Bolivia",
    countryCode: "BO",
    dialingCode: "+591",
  },
  {
    countryId: "28",
    countryName: "Bosnia and Herzegovina",
    countryCode: "BA",
    dialingCode: "+387",
  },
  {
    countryId: "29",
    countryName: "Botswana",
    countryCode: "BW",
    dialingCode: "+267",
  },
  {
    countryId: "30",
    countryName: "Brazil",
    countryCode: "BR",
    dialingCode: "+55",
  },
  {
    countryId: "31",
    countryName: "BRITISH VIRGINISLANDS",
    countryCode: "VG",
    dialingCode: "+1-284",
  },
  {
    countryId: "32",
    countryName: "Brunei",
    countryCode: "BN",
    dialingCode: "+673",
  },
  {
    countryId: "33",
    countryName: "Bulgaria",
    countryCode: "BG",
    dialingCode: "+359",
  },
  {
    countryId: "34",
    countryName: "Burkina Faso",
    countryCode: "BF",
    dialingCode: "+226",
  },
  {
    countryId: "35",
    countryName: "Burundi",
    countryCode: "BI",
    dialingCode: "+257",
  },
  {
    countryId: "36",
    countryName: "Cambodia",
    countryCode: "KH",
    dialingCode: "+855",
  },
  {
    countryId: "37",
    countryName: "Cameroon",
    countryCode: "CM",
    dialingCode: "+237",
  },
  {
    countryId: "38",
    countryName: "Canada",
    countryCode: "CA",
    dialingCode: "+1",
  },
  {
    countryId: "39",
    countryName: "Cape Verde",
    countryCode: "CV",
    dialingCode: "+238",
  },
  {
    countryId: "40",
    countryName: "CAYMAN ISLANDS",
    countryCode: "KY",
    dialingCode: "+1-345",
  },
  {
    countryId: "41",
    countryName: "Central African Republic",
    countryCode: "CF",
    dialingCode: "+236",
  },
  {
    countryId: "42",
    countryName: "Chad",
    countryCode: "TD",
    dialingCode: "+235",
  },
  {
    countryId: "43",
    countryName: "Chile",
    countryCode: "CL",
    dialingCode: "+56",
  },
  {
    countryId: "44",
    countryName: "China",
    countryCode: "CN",
    dialingCode: "+86",
  },
  {
    countryId: "45",
    countryName: "Colombia",
    countryCode: "CO",
    dialingCode: "+57",
  },
  {
    countryId: "46",
    countryName: "Comoros",
    countryCode: "KM",
    dialingCode: "+269",
  },
  {
    countryId: "47",
    countryName: "Congo",
    countryCode: "CG",
    dialingCode: "+242",
  },
  {
    countryId: "48",
    countryName: "Cook Islands",
    countryCode: "CK",
    dialingCode: "+682",
  },
  {
    countryId: "49",
    countryName: "Costa Rica",
    countryCode: "CR",
    dialingCode: "+506",
  },
  {
    countryId: "50",
    countryName: "Croatia",
    countryCode: "HR",
    dialingCode: "+385",
  },
  {
    countryId: "51",
    countryName: "Cuba",
    countryCode: "CU",
    dialingCode: "+53",
  },
  {
    countryId: "52",
    countryName: "Cyprus",
    countryCode: "CY",
    dialingCode: "+357",
  },
  {
    countryId: "53",
    countryName: "Czechia",
    countryCode: "CZ",
    dialingCode: "+420",
  },
  {
    countryId: "54",
    countryName: "Denmark",
    countryCode: "DK",
    dialingCode: "+45",
  },
  {
    countryId: "55",
    countryName: "Diego Garcia",
    countryCode: "DG",
    dialingCode: "+246",
  },
  {
    countryId: "56",
    countryName: "Djibouti",
    countryCode: "DJ",
    dialingCode: "+253",
  },
  {
    countryId: "57",
    countryName: "DOMINICA",
    countryCode: "DM",
    dialingCode: "+1-767",
  },
  {
    countryId: "58",
    countryName: "DOMINICAN REPUBLIC",
    countryCode: "DO",
    dialingCode: "+1-809",
  },
  {
    countryId: "59",
    countryName: "Ecuador",
    countryCode: "EC",
    dialingCode: "+593",
  },
  {
    countryId: "60",
    countryName: "Egypt",
    countryCode: "EG",
    dialingCode: "+20",
  },
  {
    countryId: "61",
    countryName: "El Salvador",
    countryCode: "SV",
    dialingCode: "+503",
  },
  {
    countryId: "62",
    countryName: "Equatorial Guinea",
    countryCode: "GQ",
    dialingCode: "+240",
  },
  {
    countryId: "63",
    countryName: "Eritrea",
    countryCode: "ER",
    dialingCode: "+291",
  },
  {
    countryId: "64",
    countryName: "Estonia",
    countryCode: "EE",
    dialingCode: "+372",
  },
  {
    countryId: "65",
    countryName: "Ethiopia",
    countryCode: "ET",
    dialingCode: "+251",
  },
  {
    countryId: "66",
    countryName: "Faroe Islands",
    countryCode: "FO",
    dialingCode: "+298",
  },
  {
    countryId: "67",
    countryName: "Fiji",
    countryCode: "FJ",
    dialingCode: "+679",
  },
  {
    countryId: "68",
    countryName: "Finland",
    countryCode: "FI",
    dialingCode: "+358",
  },
  {
    countryId: "69",
    countryName: "France",
    countryCode: "FR",
    dialingCode: "+33",
  },
  {
    countryId: "70",
    countryName: "French Guiana",
    countryCode: "GF",
    dialingCode: "+594",
  },
  {
    countryId: "71",
    countryName: "French Polynesia",
    countryCode: "PF",
    dialingCode: "+689",
  },
  {
    countryId: "72",
    countryName: "Gabon",
    countryCode: "GA",
    dialingCode: "+241",
  },
  {
    countryId: "73",
    countryName: "Gambia",
    countryCode: "GM",
    dialingCode: "+220",
  },
  {
    countryId: "74",
    countryName: "Georgia",
    countryCode: "GE",
    dialingCode: "+995",
  },
  {
    countryId: "75",
    countryName: "Germany",
    countryCode: "DE",
    dialingCode: "+49",
  },
  {
    countryId: "76",
    countryName: "Ghana",
    countryCode: "GH",
    dialingCode: "+233",
  },
  {
    countryId: "77",
    countryName: "Gibraltar",
    countryCode: "GI",
    dialingCode: "+350",
  },
  {
    countryId: "78",
    countryName: "Greece",
    countryCode: "GR",
    dialingCode: "+30",
  },
  {
    countryId: "79",
    countryName: "Greenland",
    countryCode: "GL",
    dialingCode: "+299",
  },
  {
    countryId: "80",
    countryName: "GRENADA",
    countryCode: "GD",
    dialingCode: "+1-473",
  },
  {
    countryId: "81",
    countryName: "GUAM",
    countryCode: "GU",
    dialingCode: "+1-671",
  },
  {
    countryId: "82",
    countryName: "Guatemala",
    countryCode: "GT",
    dialingCode: "+502",
  },
  {
    countryId: "83",
    countryName: "Guinea",
    countryCode: "GN",
    dialingCode: "+224",
  },
  {
    countryId: "84",
    countryName: "Guyana",
    countryCode: "GY",
    dialingCode: "+592",
  },
  {
    countryId: "85",
    countryName: "Haiti",
    countryCode: "HT",
    dialingCode: "+509",
  },
  {
    countryId: "86",
    countryName: "HAWAII (USA)",
    countryCode: "HI",
    dialingCode: "+1-808",
  },
  {
    countryId: "87",
    countryName: "Honduras",
    countryCode: "HN",
    dialingCode: "+504",
  },
  {
    countryId: "88",
    countryName: "Hong Kong",
    countryCode: "HK",
    dialingCode: "+852",
  },
  {
    countryId: "89",
    countryName: "Hungary",
    countryCode: "HU",
    dialingCode: "+36",
  },
  {
    countryId: "90",
    countryName: "Iceland",
    countryCode: "IS",
    dialingCode: "+354",
  },
  {
    countryId: "91",
    countryName: "India",
    countryCode: "IN",
    dialingCode: "+91",
  },
  {
    countryId: "92",
    countryName: "Indonesia",
    countryCode: "ID",
    dialingCode: "+62",
  },
  {
    countryId: "93",
    countryName: "Iran",
    countryCode: "IR",
    dialingCode: "+98",
  },
  {
    countryId: "94",
    countryName: "Iraq",
    countryCode: "IQ",
    dialingCode: "+964",
  },
  {
    countryId: "95",
    countryName: "Ireland",
    countryCode: "IE",
    dialingCode: "+353",
  },
  {
    countryId: "96",
    countryName: "Israel",
    countryCode: "IL",
    dialingCode: "+972",
  },
  {
    countryId: "97",
    countryName: "Italy",
    countryCode: "IT",
    dialingCode: "+39",
  },
  {
    countryId: "98",
    countryName: "JAMAICA",
    countryCode: "JM",
    dialingCode: "+1-876",
  },
  {
    countryId: "99",
    countryName: "Japan",
    countryCode: "JP",
    dialingCode: "+81",
  },
  {
    countryId: "100",
    countryName: "Jordan",
    countryCode: "JO",
    dialingCode: "+962",
  },
  {
    countryId: "101",
    countryName: "Kazakhstan",
    countryCode: "KZ",
    dialingCode: "+7",
  },
  {
    countryId: "102",
    countryName: "Kenya",
    countryCode: "KE",
    dialingCode: "+254",
  },
  {
    countryId: "103",
    countryName: "Kiribati",
    countryCode: "KI",
    dialingCode: "+686",
  },
  {
    countryId: "104",
    countryName: "North Korea",
    countryCode: "KP",
    dialingCode: "+850",
  },
  {
    countryId: "105",
    countryName: "South Korea",
    countryCode: "KR",
    dialingCode: "+82",
  },
  {
    countryId: "106",
    countryName: "Kuwait",
    countryCode: "KW",
    dialingCode: "+965",
  },
  {
    countryId: "107",
    countryName: "Latvia",
    countryCode: "LV",
    dialingCode: "+371",
  },
  {
    countryId: "108",
    countryName: "Lebanon",
    countryCode: "LB",
    dialingCode: "+961",
  },
  {
    countryId: "109",
    countryName: "Lesotho",
    countryCode: "LS",
    dialingCode: "+266",
  },
  {
    countryId: "110",
    countryName: "Liberia",
    countryCode: "LR",
    dialingCode: "+231",
  },
  {
    countryId: "111",
    countryName: "Libya",
    countryCode: "LY",
    dialingCode: "+218",
  },
  {
    countryId: "112",
    countryName: "Liechtenstein",
    countryCode: "LI",
    dialingCode: "+423",
  },
  {
    countryId: "113",
    countryName: "Lithuania",
    countryCode: "LT",
    dialingCode: "+370",
  },
  {
    countryId: "114",
    countryName: "Luxembourg",
    countryCode: "LU",
    dialingCode: "+352",
  },
  {
    countryId: "115",
    countryName: "Madagascar",
    countryCode: "MG",
    dialingCode: "+261",
  },
  {
    countryId: "116",
    countryName: "Malawi",
    countryCode: "MW",
    dialingCode: "+265",
  },
  {
    countryId: "117",
    countryName: "Malaysia",
    countryCode: "MY",
    dialingCode: "+60",
  },
  {
    countryId: "118",
    countryName: "Maldives",
    countryCode: "MV",
    dialingCode: "+960",
  },
  {
    countryId: "119",
    countryName: "Mali",
    countryCode: "ML",
    dialingCode: "+223",
  },
  {
    countryId: "120",
    countryName: "Malta",
    countryCode: "MT",
    dialingCode: "+356",
  },
  {
    countryId: "121",
    countryName: "Marshall Islands",
    countryCode: "MH",
    dialingCode: "+692",
  },
  {
    countryId: "122",
    countryName: "Mauritania",
    countryCode: "MR",
    dialingCode: "+222",
  },
  {
    countryId: "123",
    countryName: "Mauritius",
    countryCode: "MU",
    dialingCode: "+230",
  },
  {
    countryId: "124",
    countryName: "Mayotte",
    countryCode: "YT",
    dialingCode: "+269",
  },
  {
    countryId: "125",
    countryName: "Mexico",
    countryCode: "MX",
    dialingCode: "+52",
  },
  {
    countryId: "126",
    countryName: "Moldova",
    countryCode: "MD",
    dialingCode: "+373",
  },
  {
    countryId: "127",
    countryName: "Monaco",
    countryCode: "MC",
    dialingCode: "+377",
  },
  {
    countryId: "128",
    countryName: "Mongolia",
    countryCode: "MN",
    dialingCode: "+976",
  },
  {
    countryId: "129",
    countryName: "MONTSERRAT",
    countryCode: "MS",
    dialingCode: "+1-664",
  },
  {
    countryId: "130",
    countryName: "Morocco",
    countryCode: "MA",
    dialingCode: "+212",
  },
  {
    countryId: "131",
    countryName: "Mozambique",
    countryCode: "MZ",
    dialingCode: "+258",
  },
  {
    countryId: "132",
    countryName: "Myanmar",
    countryCode: "MM",
    dialingCode: "+95",
  },
  {
    countryId: "133",
    countryName: "Namibia",
    countryCode: "NA",
    dialingCode: "+264",
  },
  {
    countryId: "134",
    countryName: "Nauru",
    countryCode: "NR",
    dialingCode: "+674",
  },
  {
    countryId: "135",
    countryName: "Nepal",
    countryCode: "NP",
    dialingCode: "+977",
  },
  {
    countryId: "136",
    countryName: "Netherlands",
    countryCode: "NL",
    dialingCode: "+31",
  },
  {
    countryId: "137",
    countryName: "Netherlands Antilles",
    countryCode: "AN",
    dialingCode: "+599",
  },
  {
    countryId: "138",
    countryName: "New Caledonia",
    countryCode: "NC",
    dialingCode: "+687",
  },
  {
    countryId: "139",
    countryName: "New Zealand",
    countryCode: "NZ",
    dialingCode: "+64",
  },
  {
    countryId: "140",
    countryName: "Nicaragua",
    countryCode: "NI",
    dialingCode: "+505",
  },
  {
    countryId: "141",
    countryName: "Niger",
    countryCode: "NE",
    dialingCode: "+227",
  },
  {
    countryId: "142",
    countryName: "Nigeria",
    countryCode: "NG",
    dialingCode: "+234",
  },
  {
    countryId: "143",
    countryName: "Norway",
    countryCode: "NO",
    dialingCode: "+47",
  },
  {
    countryId: "144",
    countryName: "Oman",
    countryCode: "OM",
    dialingCode: "+968",
  },
  {
    countryId: "145",
    countryName: "Pakistan",
    countryCode: "PK",
    dialingCode: "+92",
  },
  {
    countryId: "146",
    countryName: "Palau",
    countryCode: "PW",
    dialingCode: "+680",
  },
  {
    countryId: "147",
    countryName: "Palestine",
    countryCode: "PS",
    dialingCode: "+970",
  },
  {
    countryId: "148",
    countryName: "Panama",
    countryCode: "PA",
    dialingCode: "+507",
  },
  {
    countryId: "149",
    countryName: "Papua New Guinea",
    countryCode: "PG",
    dialingCode: "+675",
  },
  {
    countryId: "150",
    countryName: "Paraguay",
    countryCode: "PY",
    dialingCode: "+595",
  },
  {
    countryId: "151",
    countryName: "Peru",
    countryCode: "PE",
    dialingCode: "+51",
  },
  {
    countryId: "152",
    countryName: "Philippines",
    countryCode: "PH",
    dialingCode: "+63",
  },
  {
    countryId: "153",
    countryName: "Poland",
    countryCode: "PL",
    dialingCode: "+48",
  },
  {
    countryId: "154",
    countryName: "Portugal",
    countryCode: "PT",
    dialingCode: "+351",
  },
  {
    countryId: "155",
    countryName: "Qatar",
    countryCode: "QA",
    dialingCode: "+974",
  },
  {
    countryId: "156",
    countryName: "Romania",
    countryCode: "RO",
    dialingCode: "+40",
  },
  {
    countryId: "157",
    countryName: "Russia",
    countryCode: "RU",
    dialingCode: "+7",
  },
  {
    countryId: "158",
    countryName: "Rwanda",
    countryCode: "RW",
    dialingCode: "+250",
  },
  {
    countryId: "159",
    countryName: "San Marino",
    countryCode: "SM",
    dialingCode: "+378",
  },
  {
    countryId: "160",
    countryName: "Saudi Arabia",
    countryCode: "SA",
    dialingCode: "+966",
  },
  {
    countryId: "161",
    countryName: "Senegal",
    countryCode: "SN",
    dialingCode: "+221",
  },
  {
    countryId: "162",
    countryName: "Seychelles",
    countryCode: "SC",
    dialingCode: "+248",
  },
  {
    countryId: "163",
    countryName: "Sierra Leone",
    countryCode: "SL",
    dialingCode: "+232",
  },
  {
    countryId: "164",
    countryName: "Singapore",
    countryCode: "SG",
    dialingCode: "+65",
  },
  {
    countryId: "165",
    countryName: "Slovakia",
    countryCode: "SK",
    dialingCode: "+421",
  },
  {
    countryId: "166",
    countryName: "Slovenia",
    countryCode: "SI",
    dialingCode: "+386",
  },
  {
    countryId: "167",
    countryName: "Solomon Islands",
    countryCode: "SB",
    dialingCode: "+677",
  },
  {
    countryId: "168",
    countryName: "Somalia",
    countryCode: "SO",
    dialingCode: "+252",
  },
  {
    countryId: "169",
    countryName: "Sout",
  },
];
