import React from "react";
import { useLocation } from "react-router-dom";
import IndividualInvestor from "./individualInvestor";
import InstitutionalInvestor from "./institutionalInvestor";
import Fundraiser from "./fundraiser";

const Details = () => {
  const location = useLocation();
  console.log(location.state);

  const type = location.state.type;

  const typesObj = {
    INDIVIDUAL_INVESTOR: <IndividualInvestor />,
    INSTITUTIONAL_INVESTOR: <InstitutionalInvestor />,
    FUNDRAISER: <Fundraiser />,
  };

  return <>{type ? typesObj[type] : null}</>;
};

export default Details;
