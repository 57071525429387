import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const CategorisationWrapper = styled.div`
  padding: 102px 157px;
  margin-left: auto;
  width: 75%;
  height: 100vh;
  .title {
    margin-bottom: 165px;
  }
  .infoText {
    margin-top: 120px;
  }
  .c000 {
    color: #000000;
  }
`;

const CustomLink = styled.div`
  border-radius: 5px;
  padding: 10px 30px;
  width: 320px;
  background: #35a2d1;
  cursor: pointer;
`;

const buttonLinks = [
  {
    linkText: "INDIVIDUAL INVESTOR",
    type: "INDIVIDUAL_INVESTOR",
  },
  { linkText: "INSTITUTIONAL INVESTOR", type: "INSTITUTIONAL_INVESTOR" },
  { linkText: "FUNDRAISER", type: "FUNDRAISER" },
];

const Categorisation = () => {
  const navigate = useNavigate();
  return (
    <CategorisationWrapper>
      <p className="fs36 fwB title">CATEGORISATION</p>
      <div>
        {buttonLinks.map((item, index) => {
          return (
            <CustomLink className="dF jcC mb40" key={index}>
              <div
                onClick={() =>
                  navigate("/details", { state: { type: item.type } })
                }
                className="dF jcC fs20 fwB c000"
              >
                {item.linkText}
              </div>
            </CustomLink>
          );
        })}
      </div>
      <p className="fs20 fwB c000 infoText">
        Once the User has selected a category. Then the Registration process
        will be accordingly:
      </p>
    </CategorisationWrapper>
  );
};

export default Categorisation;
