import React from "react";
import Select from "react-select";
import styled from "styled-components";

const CustomSelectWrapper = styled(Select)`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  div {
    border-radius: 0px;
    border: none;
  }
  .css-1u9des2-indicatorSeparator {
    display: none;
  }
  .css-1xc3v61-indicatorContainer {
    color: #000000;
    font-weight: bold;
  }
  /* .ytJg:hover {
    border-color: none;
  }
  .css-t3ipsp-control:hover {
    border-color: none;
  } */
  .css-13cymwt-control {
    background: #e8e5e5;
  }
  .css-1nmdiq5-menu {
    background: #e8e5e5;
  }
  .css-1nmdiq5-menu:hover {
    background: #e8e5e5;
  }
`;

const CustomSelect = (props) => {
  return <CustomSelectWrapper {...props} />;
};

export default CustomSelect;
