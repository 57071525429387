import React, { useEffect } from "react";
import styled from "styled-components";
import FundtLogo from "../assets/fundt-logo.png";

const Wrapper = styled.div`
  height: 100vh;
`;

const EmailConfirmation = () => {
  useEffect(() => {
    // Extracting the query parameters
    const urlParams = new URLSearchParams(window.location.search);
    const accountId = urlParams.get("accountId");

    const token = urlParams.get("token");

    // Check if accountId and token are present
    if (accountId && token) {
      // Constructing the verification URL
      const verificationUrl = `https://registration.fundt.om/accounts/email-verification?accountId=${accountId}&token=${token}`;

      // Redirecting to the verification URL
      window.location.href = verificationUrl;
    } else {
      // Handle error case if required
      console.error("Missing accountId or token");
    }
  }, []);
  return (
    <Wrapper style={{ flexDirection: "column" }} className="dFA jcC">
      <img src={FundtLogo} alt="Fundt Logo" style={{ width: "200px" }} />
      <p style={{ marginTop: "20px" }} className="fs36 fwB">
        Thank you for choosing FundT!
      </p>
      <p style={{ marginTop: "20px" }} className="">
        Your email has been verified successfully.
      </p>
    </Wrapper>
  );
};

export default EmailConfirmation;
