import React from "react";
import styled from "styled-components";

const MessageWrapper = styled.div`
  margin-top: 6px;
  color: red;
`;

const DropDownErrorMessage = ({ children }) => {
  return <MessageWrapper className="fs12">{children}</MessageWrapper>;
};

export default DropDownErrorMessage;
