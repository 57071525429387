import React from "react";
import FundtLogo from "../../assets/fundt-logo.svg";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

const Wrapper = styled.div`
  width: 25%;
  background: #ede6e6;
  height: 100vh;
  .mt162 {
    margin-top: 162px;
  }
`;

const LinksWrapper = styled.div`
  color: #35a2d1;
  width: max-content;
  .circle {
    height: 28px;
    width: 28px;
    margin-right: 10px;
    border-radius: 50%;
    background: #35a2d1;
  }
  .line-item:not(:last-child) {
    height: 100px;
    margin-left: 13.5px;
    border-left: 2px solid #35a2d1;
  }
`;

const CustomLink = styled.div`
  color: #35a2d1;
`;

const sideBarLinks = [
  {
    linkText: "REGISTRATION",
    href: "/user-registration",
  },
  {
    linkText: "CATEGORISATION",
    href: "/categorisation",
  },
  {
    linkText: "DETAILS",
    href: "/details",
  },
];

const SideBar = () => {
  const location = useLocation();
  return (
    <Wrapper className="pF">
      <img src={FundtLogo} alt="Fundt Logo" width="216" height="120" />
      <div className="dFA jcC mt162">
        <LinksWrapper className="dF fdC pR">
          {sideBarLinks.map((item, index) => {
            return (
              <>
                <div key={index} className="w100 dFA stepper-item">
                  <div className="circle"></div>
                  <p
                    className={`${
                      location.pathname.includes(item.href) ? "fwB" : ""
                    }`}
                    path={location.pathname}
                  >
                    <CustomLink>{item.linkText}</CustomLink>
                  </p>
                </div>
                <div className="line-item"></div>
              </>
            );
          })}
        </LinksWrapper>
      </div>
    </Wrapper>
  );
};

export default SideBar;
