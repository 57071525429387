import "./App.css";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { configure } from "axios-hooks";
import { raiseToast } from "../helper";
import { AppProvider } from "../context/app";
import Routing from "./routing";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const Wrapper = styled.div`
  min-height: 100vh;
`;

axios.interceptors.request.use(
  async (config) => {
    let token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (data) => {
    console.log("response", data);
    return data;
  },
  (error) => {
    console.log("error", error?.response?.status);
    if (error?.message === "Network Error") {
      raiseToast("error", "Check Your Internet Connection");
    } else if (error?.response?.status !== 200) {
      raiseToast("error", error.response?.data?.message);
    }
    return Promise.reject(error);
  }
);

configure({ axios });

function App() {
  return (
    <Wrapper className="dF App">
      <AppProvider>
        <ToastContainer />
        <BrowserRouter>
          <Routing />
        </BrowserRouter>
      </AppProvider>
    </Wrapper>
  );
}

export default App;
