import React from "react";
// If you use module bundler
import {
  defineComponents,
  DocumentReaderDetailType,
  DocumentReaderService,
  DocumentReaderWebComponent,
} from "@regulaforensics/vp-frontend-document-components";
import LICENSE_KEY from "../constants/regula.license";
import { raiseToast } from "../../helper";

const containerStyle = {
  display: "flex",
  //   position: "absolute",
  height: "100vh",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
};

const buttonStyle = {
  padding: "10px 30px",
  color: "white",
  fontSize: "16px",
  borderRadius: "5px",
  backgroundColor: "#3498db",
  border: "1px solid #3498db",
  cursor: "pointer",
};

const KYCDetail = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const containerRef = React.useRef(null);
  const elementRef = React.useRef(null);
  const listener = (data) => {
    if (data.detail.action === "PROCESS_FINISHED") {
      const status = data.detail.data?.status;
      const isFinishStatus = status === 1 || status === 2;

      if (!isFinishStatus || !data.detail.data?.response) return;
      //   console.log("data.detail.data.response", data.detail.data.response);
      console.log("data.detail.data.response", data);
      raiseToast("success", "KYC details submitted successfully!");
    }

    if (data.detail?.action === "CLOSE") {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    const containerCurrent = containerRef.current;

    window.RegulaDocumentSDK = new DocumentReaderService();

    defineComponents().then(() => {
      //   handleFileInputChange();
      window.RegulaDocumentSDK.initialize({
        license:
          "AAEAAGk/0fc4ZKefvVmXjNA8hQhkOBGbWm0zSFQPKCZpx8B6xlr+lj84vaKXgBANUYy9NVsxwxFUiQglUCfbxmUcxB7r97QkfoRlvx8MvTFzRA4lB21seZIZcBSg47/bKTZBvkCvERaqNyarz78mGKO6eay+isY+MSpFhuNjru8FECdhvARjFl8E4eQhA6yBDOZxM/2X3XMXs5/hAXuHRBL8JD1MGuNyk3shBiVV0sxlEQOD2cPdznS9IFdlrD9SrZ4AIjIZ2jaD1PODh5Z6WSbXBDiYQsl86TfVu0xkQ+wpbtKoimJcn9ox77Bzucmy4EEODw1J1lpgACJ0TyVpEZPfi2PkAAAAAAAAEBwY/diQaQCPmLUCBdQ7kPbax/Wk7iIalQIq4rrXnOCGgqTWqDyynkClSP8My3guZ+iUPhZk9dg8zVbD0gxZUyhR1jwrmy7HCuGDk4KhqvPAslMkKXGlT/vHB+k92aBhhaVnxYqLbdMsfqe9EuXhH9zsitMaT8EfdLnEZX9VNEx4iDlOs6+H1zLE5zLAUVAyLKflIB55q+tlXGtQIUmPTngpgUHKBv7SjsGSxaqOif5Ud1OkVCYvw5Lr9Lu3e3MycPHm+lGl8oCh9sz/9xszRvAZ2liDQkqnfRk38VWxaQXC",
      });
    });
    // To use the document-reader component on test environments, you have to set the base64 license
    // defineComponents().then(() => window.RegulaDocumentSDK.initialize({ license: 'YOUR_BASE64_LICENSE_KEY' }));

    if (!containerCurrent) return;

    containerCurrent.addEventListener("document-reader", listener);

    return () => {
      window.RegulaDocumentSDK.shutdown();
      containerCurrent.removeEventListener("document-reader", listener);
    };
  }, []);

  React.useEffect(() => {
    const elementRefCurrent = elementRef.current;

    if (!elementRefCurrent) return;

    elementRefCurrent.settings = {
      startScreen: true,
      changeCameraButton: true,
    };
  }, [isOpen]);

  const handleFileInputChange = () => {
    const file = LICENSE_KEY;
    const reader = new FileReader();

    reader.onload = () => {
      const base64String = btoa(
        new Uint8Array(reader.result).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      );

      console.log("Base64 String:", base64String);
      // Do something with the base64String, e.g., set it to state
    };

    reader.onerror = (error) => {
      console.error("Error:", error);
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <div style={containerStyle} ref={containerRef}>
      {isOpen ? (
        <div style={{ width: "50%" }}>
          <document-reader ref={elementRef}></document-reader>
        </div>
      ) : (
        <button style={buttonStyle} onClick={() => setIsOpen(true)}>
          Start KYC
        </button>
      )}
    </div>
  );
};

export default KYCDetail;
