/* eslint-disable react/prop-types */
import React, { createContext, useState } from "react";

const initialState = {
  accountObj: {},
};
//create context

export const AppContext = createContext(initialState);

//Provider Component

export const AppProvider = ({ children }) => {
  const [accountObj, setAccountObj] = useState({});
  const [loader, setLoader] = useState(false);

  function setUser(data) {
    localStorage.setItem("accountId", data?.accountId);
    localStorage.setItem("token", data.token);
  }

  return (
    <AppContext.Provider
      value={{
        setUser,
        accountObj,
        setAccountObj,
        loader,
        setLoader,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
