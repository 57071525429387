import React, { useContext } from "react";
import Loading from "react-fullscreen-loading";
import { Route, Routes, useLocation } from "react-router-dom";
import Dashboard from "../components/dashboard/dashboard";
import SideBar from "../components/sideBar/sideBar";
import Categorisation from "../container/categorisation";
import Details from "../container/details";
import ThankYou from "../container/thankYou";
import UserRegistration from "../container/userRegistration";
import { AppContext } from "../context/app";
import KYCDetail from "../components/kycDetail/kycDetail";
import EmailConfirmation from "../container/EmailConfirmation";
import { ROUTES } from "./RoutesPaths";
const Routing = () => {
  const { loader } = useContext(AppContext);
  const location = useLocation();
  return (
    <Dashboard>
      <Loading
        loading={loader}
        background={"rgba(60, 60, 60, 0.6)"}
        loaderColor="#efefef"
      />
      {location.pathname.match(ROUTES.thankYou) ||
      location.pathname.match(ROUTES.verifiedEmail) ||
      location.pathname.match(ROUTES.kycDetails) ? null : (
        <SideBar />
      )}
      <main className="">
        <Routes>
          <Route path="/" element={<UserRegistration />} />
          <Route
            path={ROUTES.userRegistration}
            element={<UserRegistration />}
          />
          <Route path={ROUTES.categorisation} element={<Categorisation />} />
          <Route path={ROUTES.details} element={<Details />} />
          <Route path={ROUTES.thankYou} element={<ThankYou />} />
          <Route path={ROUTES.kycDetails} element={<KYCDetail />} />
          <Route path={ROUTES.verifiedEmail} element={<EmailConfirmation />} />
        </Routes>
      </main>
    </Dashboard>
  );
};

export default Routing;
